// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/:id/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  productType: {
    root: path(ROOTS_DASHBOARD, '/product-type'),
    list: path(ROOTS_DASHBOARD, '/product-type/list'),
    newProductType: path(ROOTS_DASHBOARD, '/product-type/new'),
    editById: path(ROOTS_DASHBOARD, '/product-type/:id/edit')
  },
  quote: {
    root: path(ROOTS_DASHBOARD, '/quote'),
    list: path(ROOTS_DASHBOARD, '/quote/list'),
    newQuote: path(ROOTS_DASHBOARD, '/quote/new'),
    viewQuote: path(ROOTS_DASHBOARD, '/quote/:id/view'),
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    newCustomer: path(ROOTS_DASHBOARD, '/customer/new'),
    editById: path(ROOTS_DASHBOARD, '/customer/:id/edit')
  },
  location: {
    root: path(ROOTS_DASHBOARD, '/location')
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    newProduct: path(ROOTS_DASHBOARD, '/product/new'),
    editById: path(ROOTS_DASHBOARD, '/product/:id/edit')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:id'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    chekoutCustomers: path(ROOTS_DASHBOARD, '/e-commerce/checkout/customers'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
