import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback, useContext } from 'react';
import useInterval from '@use-it/interval';
// material
import { Box } from '@material-ui/core';
// utils
import { SocketContext } from '../contexts/SocketContext';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { pathname } = useLocation();
  const { user, isAuthenticated } = useAuth();
  const socket = useContext(SocketContext);

  useInterval(() => {
    if(user && isAuthenticated && user.typeId !== 1){
      const { id, firstName, lastName } = user; 
      if("geolocation" in navigator){
        navigator.geolocation.getCurrentPosition(position => {
          console.log(position.coords);
          socket.emit('location', JSON.stringify({ id, name: `${firstName} ${lastName}`, position: {
            lat: position.coords.latitude, 
            lng: position.coords.longitude
          }}));
        });
      }
    }
  }, 30000);


  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
