import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import api from 'axios';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  customers: [],
  product: null,
  sortBy: null,
  quoteList: [],
  filterCategoryOptions: [],
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    markup: 0,
    customer: null,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    discountPercentage: 0,
    markupPercentage: 0,
    billing: null,
    enableDiscount: true,
    enableMarkup: true,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPercentagesSuccess(state, action) {
      const { markupPercentage, discountPercentage } = action.payload;
      state.isLoading = false;
      state.checkout.markupPercentage = markupPercentage;
      state.checkout.discountPercentage = discountPercentage;
    },
    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getQuotesSuccess(state, action) {
      state.isLoading = false;
      state.quoteList = action.payload;
    },

    getCustomersSucces(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getFilterCategoryOptionsSucces(state, action) {
      state.isLoading = false;
      state.filterCategoryOptions = action.payload;
    },

    // DELETE PRODUCT
    deleteProduct(state, action) {
      state.products = reject(state.products, { id: action.payload });
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    addCustomer(state, action) {
      state.checkout.customer = action.payload;
    },

    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((product) => product.price * product.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;
      const markup = cart.length === 0 ? null : state.checkout.markup;


      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.markup = markup;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount + markup;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(state.checkout.cart, (item) => item.id !== action.payload);
      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
      state.checkout.customer = null;
      state.checkout.markup = 0;
      state.checkout.enableMarkup = true;
      state.checkout.enableDiscount = true;
      state.checkout.discountPercentage = 0;
      state.checkout.markupPercentage = 0;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    removeDiscount(state, action) {
      const discount = 0;
      state.checkout.discount = discount;
      state.checkout.enableMarkup = true;
      state.checkout.enableDiscount = false;
      state.checkout.total = state.checkout.subtotal - discount + state.checkout.markup;
    },

    removeMarkup(state, action) {
      const markup = 0;
      state.checkout.markup = markup;
      state.checkout.enableMarkup = false;
      state.checkout.enableDiscount = true;
      state.checkout.total = state.checkout.subtotal - markup + state.checkout.markup;
    },

    applyDiscount(state, action) {
      const {applied, discount } = action.payload;
      if(applied) {
        state.checkout.enableMarkup = false;
      } else {
        state.checkout.enableMarkup = true;
      }
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount + state.checkout.markup;
    },

    applyShipping(state, action) {
      const {applied, markup } = action.payload;
      if(applied) {
        state.checkout.enableDiscount = false
      } else {
        state.checkout.enableDiscount = true;
      }

      state.checkout.markup = markup;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + markup;
    },

    applyMarkup(state, action) {
      const markup = action.payload;
      state.checkout.markup = markup;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + markup;
    },
    
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity,
  addCustomer,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: response } = await api.get(`${process.env.REACT_APP_MPAC_WS}/products/GetProducts`);
      const { data: products, message, success } = response;
      // const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getCustomers() {
  return async (dispatch) => {
    try {
      const { data: response } = await api.get(`${process.env.REACT_APP_MPAC_WS}/customers/GetCustomers`);
      const { data: customers, message, success } = response;
      dispatch(slice.actions.getCustomersSucces(customers));
    } catch (error) {
      dispatch(slice.action.hasError(error));
    }
  }
}

export function getQuoteList(userId) {
  return async (dispatch) => {
    try {
      const { data: response } = await api.get(`${process.env.REACT_APP_MPAC_WS}/quotes/GetQuotes/${userId}`);
      const { data: quotes, message, success } = response;
      dispatch(slice.actions.getQuotesSuccess(quotes));
    } catch (error) {
      dispatch(slice.action.hasError(error));
    }
  }
}

export function getParameters() {
  return async (dispatch) => {
    try{
      const {data : response } = await api.get(`${process.env.REACT_APP_MPAC_WS}/parameters/GetParameters`);
      const { data: parameters } = response;
      const discount = parameters.find(parameter => parameter.name === 'discount');
      const markup = parameters.find(parameter => parameter.name === 'markup');
      
      dispatch(slice.actions.getPercentagesSuccess({
        discountPercentage : parseFloat(discount.value),
        markupPercentage : parseFloat(markup.value)
      }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFilterCategoryOptions() {
  return async (dispatch) => {
    try {
      const { data: response } = await api.get(`${process.env.REACT_APP_MPAC_WS}/product-types/GetProductTypes`);
      const { data: categories, message, success } = response;
      dispatch(slice.actions.getFilterCategoryOptionsSucces(categories));
    } catch (error) {
      dispatch(slice.action.hasError(error));
    }
  }
}

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: response } = await api.get(`${process.env.REACT_APP_MPAC_WS}/products/GetProduct/${id}`);
      const { data: products } = response;
      if (products.length > 0) {
        dispatch(slice.actions.getProductSuccess(products[0]));
      } else {
        dispatch(slice.actions.hasError(`Product not found`));
      }
      /* const response = await axios.get('/api/products/product', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product)); */
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
