import { useEffect, useState } from 'react';
import { io } from "socket.io-client";
import useInterval from '@use-it/interval';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
import { SocketProvider } from './contexts/SocketContext';

// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';


// ----------------------------------------------------------------------



export default function App() {
  const { isInitialized } = useAuth();

  /* useInterval(() => {
    if(socket) {
      socket.emit("message", "hello worl");
    }
  }, 5000); */

  
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <ScrollToTop />
            <GoogleAnalytics />
            <SocketProvider>
            {isInitialized ? <Router /> : <LoadingScreen />}
            </SocketProvider>
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
