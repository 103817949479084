// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  category: getIcon('ic_category'),
  product: getIcon('ic_product'),
  map: getIcon('ic_map'),
  customer: getIcon('ic_customer'),
  quote: getIcon('ic_quote')
};

const sidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      { title: 'customer', path: PATH_DASHBOARD.customer.list, icon: ICONS.customer, roles: ['Admin'] },
      { title: 'user', path: PATH_DASHBOARD.user.list, icon: ICONS.user, roles: ['Admin'] },
      { title: 'Product Type', path: PATH_DASHBOARD.productType.list, icon: ICONS.category, roles: ['Admin'] },
      { title: 'Product', path: PATH_DASHBOARD.product.list, icon: ICONS.product, roles: ['Admin'] },
      { title: 'Location', path: PATH_DASHBOARD.location.root, icon: ICONS.map, roles: ['Admin'] },
      // MANAGEMENT : E-COMMERCE
      {
        title: 'Quote',
        path: PATH_DASHBOARD.quote.root,
        icon: ICONS.quote,
        roles: ['Admin', 'Specialist'],
        children: [
          // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'list', path: PATH_DASHBOARD.quote.list },
          // { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.checkout },
          // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },
    ]
  }
];

export default sidebarConfig;
