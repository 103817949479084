// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src='/static/mock-images/avatars/avatar_default.jpg'
      alt={user.firstName}
      color='default'
      {...other}
    />
  );
}
