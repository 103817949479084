import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  productTypeList: [],
};

const slice = createSlice({
  name: 'productType',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProductTypeListSuccess(state, action) {
        state.isLoading = false;
        state.productTypeList = action.payload;
    }

  }
});

// Reducer
export default slice.reducer;

export function getProductTypeList() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_MPAC_WS}/product-types/GetProductTypes`);
            const { data: productTypes, success, message } = response;
            dispatch(slice.actions.getProductTypeListSuccess(productTypes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}


