import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Login />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/quote/list" replace /> },
        {
          path: 'e-commerce',
          children: [
            { path: '/', element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:id', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
            { path: 'checkout/customers', element: <CustomerListCard />}
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/list" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/:id/edit', element: <UserCreate /> },
          ]
        },
        {
          path: 'location',
          children: [
            {path: '/', element: <LocationMap /> }
          ]
        },
        {
          path: 'product-type',
          children: [
            { path: '/', element: <ProductTypeList /> },
            { path: 'list', element: <ProductTypeList /> },
            { path: 'new', element: <ProductTypeCreate /> },
            { path: '/:id/edit', element: <ProductTypeCreate /> },
          ]
        },
        {
          path: 'customer',
          children: [
            { path: '/', element: <CustomerList /> },
            { path: 'list', element: <CustomerList /> },
            { path: 'new', element: <CustomerCreate /> },
            { path: 'new/checkout', element: <CustomerCreate />},
            { path: '/:id/edit', element: <CustomerCreate /> },
          ]
        },
        {
          path: 'quote',
          children: [
            { path: '/', element: <QuoteList /> },
            { path: 'list', element: <QuoteList /> },
            { path: 'new', element: <CustomerCreate /> },
            { path: '/:id/view', element: <EcommerceInvoice /> },
          ]
        },
        {
          path: 'product',
          children: [
            { path: '/', element: <EcommerceProductList /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'new', element: <EcommerceProductCreate /> },
            { path: '/:id/edit', element: <EcommerceProductCreate /> },
          ]
        },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const CustomerListCard = Loadable(lazy(() => import('../pages/dashboard/CustomerListCard')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const ProductTypeList = Loadable(lazy(() => import('../pages/dashboard/ProductTypeList')));
const ProductTypeCreate = Loadable(lazy(() => import('../pages/dashboard/ProductTypeCreate')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const LocationMap = Loadable(lazy(() => import('../pages/dashboard/LocationMap')));
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/CustomerList')));
const CustomerCreate = Loadable(lazy(() => import('../pages/dashboard/CustomerCreate')));
const QuoteList = Loadable(lazy(() => import('../pages/dashboard/QuoteList')));
