import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  customerList: [],
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCustomersSuccess(state, action) {
        state.isLoading = false;
        state.customerList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCustomerList() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_MPAC_WS}/customers/GetCustomers`);
            const { data: customers, success, message } = response;
            dispatch(slice.actions.getCustomersSuccess(customers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}


